
import { showModal } from '@/lib/modal';
import { useStore } from '@/store';
import t from '@/translation';
import { defineComponent, ref, computed } from '@vue/runtime-core';
import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent,
  IonFabButton,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonButtons,
  IonIcon,
  
  
  onIonViewDidEnter,
  
} from '@ionic/vue';

import { 
  createOutline,
  checkmark,
  trashOutline,
  addCircle,
} from 'ionicons/icons';
import budgetIcons from '@/lib/budget_icons';
import { useRouter } from 'vue-router';
import { apiEndpoints } from '@/lib/api-endpoints';
import ExportButton from '@/components/ExportButton.vue';

export default  defineComponent({
  name: 'Budget',
  components: { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent,
    IonFabButton,
    //IonList,
    //IonItem,
    //IonLabel,
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonButtons,
    IonIcon,

    ExportButton,
  },
  setup(){

    const store = useStore();
    const router = useRouter();

    

    const editMode = ref(false);

    onIonViewDidEnter(async ()=>{
      await Promise.all([
        store.dispatch('getBudgetGroups'),
        store.dispatch('getBudgets'),
      ]);

      if (! window.localStorage.getItem("budgetIntroShown")){
        showModal(t('Welcome to the Budget page'), t('Track payments made to vendors by updating the Paid field of each item.  This ensures the outstanding balance is up to date.\n\n  \'Wish List\' items are not included in your total until you move the items to \'Essential\''), "", t("Got It"));
        window.localStorage.budgetIntroShown = true;
      }

    });

    const rowList = computed(() =>{

      const BUDGET = parseFloat(store.getters.wedding.budget); 
      let TOTAL = 0.0; 
      let PAID = 0.0; 
      let BALANCE = 0.0; 
      let WISHLIST = 0.0; 

      for(let i = 0; i < store.state.budgets.length; i ++){
        const budget = store.state.budgets[i];
        const amount = parseFloat(budget.amount);
        const deposit = parseFloat(budget.deposit);
        if (budget.importance == 0){ 
          // non-wishlist
          TOTAL += amount * budget.quantity;
          PAID += budget.paid + deposit;
        } else {
          // wishlist
          WISHLIST += amount * budget.quantity;
          PAID += budget.paid + deposit;
        }
      }

      BALANCE = TOTAL - PAID;

      

      return [
        { title:"Budget", value:BUDGET},
        { title:"Total", value:TOTAL},
        { title:"Paid", value:PAID},
        { title:"Balance", value:BALANCE},
        { title:"Wish List", value:WISHLIST},
      ];
    });

    function groupIconClicked(event: PointerEvent, groupId: number){
      if (editMode.value){
        router.push('/tabs/budget/new_category/' + groupId);
      } else {
        router.push('/tabs/budget/category/' + groupId);
      }
    }

    function deleteIconClicked(event: PointerEvent, groupId: number){
      event.stopPropagation();
      store.dispatch('deleteBudgetGroup', groupId)
    }

    return {
      createOutline,
      checkmark,
      trashOutline,
      addCircle,

      rowList,
      budgetIcons,

      store,
      router,
      editMode,
      groupIconClicked,
      deleteIconClicked,

      apiEndpoints,
    }
    
  },
});
